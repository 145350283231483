import { Box } from "@mui/material";
import FooterBtn from "../FooterBtn";
import { useState } from "react";
import i18next from "i18next";
import useNavigator from "hooks/useNavigator";
import speedDatingService from "services/speedDatingService";

export default function AddPayment({ speedData = {} }) {
	const [loading, setLoading] = useState(false);
	const { navigateToPayVideoPage } = useNavigator();

	const getPayment = async () => {
		if (!speedData?.key) return;

		const res = await speedDatingService.getSpeedDatingPaymentUrl(speedData?.key);
		if (res.data?.success) {
			navigateToPayVideoPage({ urlName: speedData.urlName, iframeUrlLink: res.data?.url });
		}
		setLoading(false);
	};

	const handleClick = () => {
		setLoading(true);
		getPayment();
	};

	return (
		<>
			<Box>
				<h3 className="title">{`עוד רגע ואתם שם..`}</h3>
			</Box>

			<Box className="text">
				<Box>
					{`האירוע אמנם ללא תשלום 🤗 אך, על מנת להבטיח שכולם יגיעו ויקחו חלק פעיל, האירוע מתקיים עם דמי רצינות בהזנת פרטי אשראי, כך שבמקרה של אי הגעה תחויבו ב${
						speedData?.price ?? ""
					}  ש"ח. `}
					<Box>
						<u>
							<b>{`אם תגיעו למפגשים, לא תחויבו כלל! `}</b>
						</u>
					</Box>
				</Box>
			</Box>
			<Box className="footer">
				<FooterBtn
					handleClick={handleClick}
					text={i18next.t("למעבר לתשלום דמי רצינות")}
					loading={loading}
				/>
			</Box>
		</>
	);
}
