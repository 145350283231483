import i18next from "i18next";
import { Button, Modal, ModalFooter, ModalHeader, ModalBody } from "reactstrap";

import usePopups from "hooks/usePopups";
import { getPopups } from "redux/popups/selectors";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { getUserProfile } from "redux/profile/selectors";

export default () => {
	const { showSubscriptionPopup, closePopup } = usePopups();
	const { name } = useSelector(getUserProfile);

	const handleClick = () => {
		showSubscriptionPopup();
	};

	return (
		<Modal isOpen={true} toggle={closePopup} className="simpleDialog">
			<ModalHeader>{`היי ${name}`}</ModalHeader>
			<ModalBody>
				<div>{`עקב ביקוש גבוה לאירוע הוידאו דייט הקרוב, האירוע פתוח כעת למנויים בלבד.`}</div>
			</ModalBody>
			<ModalFooter>
				<Button size="md" color="primary" onClick={handleClick} className="btn-rounded ">
					{"לרכישת מנוי"}
				</Button>
			</ModalFooter>
		</Modal>
	);
};
