import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import i18next from "i18next";
import _ from "lodash";

import ArrowUpImage from "assets/images/purchase_account_arrow_up.svg";
import usePopups from "hooks/usePopups";
import useShowJoinNowBtn from "hooks/useShowJoinNowBtn";
import { getIsPlatformIOS, getIsPlatformWeb } from "redux/init/selectors";
import { getIsTester, getUserProfile } from "redux/profile/selectors";
import {
	getSale,
	getSubscriptionData,
	getSubscriptionsIsSoonExpired,
} from "redux/subscription/selectors";
import { Button } from "reactstrap";
import { getAgeByDob } from "helpers/date";
import speedDateStatus from "consts/speedDateStatus";
import speedDatingService from "services/speedDatingService";
import useNavigator from "hooks/useNavigator";
import moment from "moment";
import promotion from "services/promotion";
import { getUserGeneral } from "redux/general/selectors";
import systemService from "services/system";

export default () => {
	const navigate = useNavigator();
	const isPlatformWeb = useSelector(getIsPlatformWeb);
	const subscription = useSelector(getSubscriptionData);
	const subscriptionsIsSoonExpired = useSelector(getSubscriptionsIsSoonExpired);

	const { gender } = useSelector(getUserProfile);
	const currentUser = useSelector(getUserProfile) || {};
	const showJoinNowBtn = useShowJoinNowBtn();
	const {
		showSubscriptionPopup,
		showSubscriptionDealPopup,
		showSaleSubscriptionPopup,
		showPromotionsSalePopup,
	} = usePopups();
	const sale = useSelector(getSale);
	const animatedName = "animate__bounceInDown";
	const isPlatformIOS = useSelector(getIsPlatformIOS);
	const userGeneral = useSelector(getUserGeneral);
	const [userTestPermission, setUserTestPermission] = useState(false);
	const isTester = useSelector(getIsTester);

	const getUrl = () => {
		if (process.env.REACT_APP_ENV === "shlish") {
			if (isPlatformIOS) return "https://apple.co/48O1JQ6";
			return "https://bit.ly/42joSrq";
		} else {
			return "https://jsmart.app/invite/Ewq2";
		}
	};
	const goToTheStore = () => window.open(getUrl(), "_blank");

	const navigateToAccountVideoDate = () => {
		navigate.navigateToAccountVideoDate();
	};

	const navigateToSpeedDate = (url) => {
		navigate.navigateToPath(`/speedDating/${url}`);
	};

	const handleSaleSubscriptionPopup = () => {
		showSaleSubscriptionPopup(undefined, undefined, true, null);
	};

	const [current, setCurrent] = useState();
	const [speedDating, setSpeedDating] = useState();

	useEffect(async () => {
		if (_.isNil(currentUser.uid)) {
			return;
		}
		if (!userTestPermission) return;

		const data = await speedDatingService.getNextSpeedDate();
		if (!data) return;

		let sData;
		sData = Object.values(data || {}).find((ev) => {
			const canRegister = speedDatingService.checkIfUserCanRegisterToEventByFilters(
				ev,
				currentUser
			);
			return ev?.status === speedDateStatus.start && canRegister && !!ev?.bottomStrip;
		});

		if (sData?.key) {
			const userInSpeedDate = await speedDatingService.getUserInSpeedDateNotInStartStatus(
				sData?.key,
				currentUser?.uid
			);

			if (
				!userInSpeedDate &&
				sData?.endUsersRegister &&
				!moment().isAfter(moment(sData?.endUsersRegister))
			)
				setSpeedDating(sData);
		}
	}, [currentUser.uid, userTestPermission]);

	const getTime = (min) => {
		if (!min || min < 0) return false;
		if (min > 1) return `${min} דקות`;
		if (min == 1) return `דקה`;
	};

	useEffect(async () => {
		if (_.isNil(currentUser.uid)) {
			return;
		}

		if (currentUser?.lastSpeedDateTime) {
			const now = moment();
			const lastSpeedDateTime = moment(currentUser.lastSpeedDateTime);
			const thirtyMinutesBeforeSpeedDateTime = moment(currentUser.lastSpeedDateTime).subtract(
				30,
				"minutes"
			);

			if (now.isBetween(thirtyMinutesBeforeSpeedDateTime, lastSpeedDateTime)) {
				const minutesDifference = lastSpeedDateTime.diff(now, "minutes");
				const minutesText = getTime(minutesDifference);
				const speedData = await speedDatingService.getNextSpeedDate();

				const { urlName = null } = Object.values(speedData || {})[0] || {};
				if (!!minutesText && urlName) {
					return setCurrent({
						type: "speedDating",
						header: `עוד ${minutesText} לפגישה הקרובה`,
						func: () => navigateToSpeedDate(speedData?.urlName),
						color: "secondary",
						className: "videoDate-bg",
					});
				}
			}
		}
		if (speedDating && userTestPermission) {
			//need change false to speedDating
			setCurrent({
				type: "speedDating",
				header: "חדש! וידאו דייט 🤍 להרשמה",
				func: navigateToAccountVideoDate,
				color: "secondary",
				className: "videoDate-bg",
			});
		} else if (!_.isNil(showJoinNowBtn)) {
			if (showJoinNowBtn) {
				if (subscriptionsIsSoonExpired) {
					setCurrent({
						type: "joinNow",
						header: `${currentUser.name}, מבצע במיוחד בשבילך!`,
						func: showSubscriptionDealPopup,
						color: "success",
						className: "red-bg",
					});
				} else if (!!sale && !!sale.label) {
					setCurrent({
						type: "sale",
						header: sale.label,
						func: handleSaleSubscriptionPopup,
						color: "success",
						className: "red-bg",
					});
				} else {
					setCurrent({
						type: "joinNow",
						header: i18next.t(`Strip.Join Now_${gender}`),
						func: showSubscriptionPopup,
						color: "success",
						className: "",
					});
				}
			} else {
				const promotionResult = await promotion.getActivePromotionsSalePlan();

				if (!userGeneral?.isPromoteSale && !!promotionResult) {
					const title = `${currentUser.name}, ${currentUser.gender ? "קדמי" : "קדם"} את הכרטיס שלך`;
					setCurrent({
						type: "joinNow",
						header: title,
						func: () => showPromotionsSalePopup(promotionResult),
						color: "success",
						className: "",
					});
				} else setCurrent(null);
			}
		}
	}, [
		showJoinNowBtn,
		isPlatformWeb,
		subscription,
		currentUser.uid,
		speedDating,
		userTestPermission,
	]);

	useEffect(() => {
		const fetchTestSiteStatus = async () => {
			const testSite = await systemService.fetchSiteInTest();
			let havePermission = false;
			if ((testSite && isTester) || !testSite) {
				havePermission = true;
			}
			setUserTestPermission(havePermission);
		};

		fetchTestSiteStatus();
	}, []);

	return (
		<>
			{current && (
				<Button
					className={`bottomStrip ${current?.className}`}
					onClick={current.func}
					color={current.color}>
					<img src={ArrowUpImage} />
					<div className={`animate__animated ${animatedName}`} style={{ minWidth: "fit-content" }}>
						{current.header}
					</div>
					<img src={ArrowUpImage} />{" "}
				</Button>
			)}
		</>
	);
};
