import { useSelector } from "react-redux";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";

import usePopups from "hooks/usePopups";
import { getPopups } from "redux/popups/selectors";
import { useEffect, useState } from "react";
import speedDatingService from "services/speedDatingService";
import Feedback from "components/speedDate/Feedback";

export default () => {
	const { closePopup } = usePopups();
	const { data } = useSelector(getPopups);
	const [feedbackQuestions, setFeedbackQuestions] = useState([]);
	const [myFeedback, setMyFeedback] = useState({});

	useEffect(() => {
		console.log("data", data);
		if (!data?.speedData || !data?.uid || !data?.uidMatch || !data?.currentDate) closePopup();

		speedDatingService.getFeedbackQuestions().then((data) => {
			if (!data) return;
			const fqData = Object.values(data || {}).filter((item) => !item?.deleted && !!item?.active);
			setFeedbackQuestions(fqData);
		});
		speedDatingService.getUserFeedback(data.speedData?.key, data?.uid).then((data) => {
			if (!data) return;
			setMyFeedback(data);
		});
	}, []);

	return (
		<Modal isOpen={true} toggle={closePopup} className="simpleDialog">
			<div className="user-chat-nav ">
				<Button
					color="none"
					type="button"
					onClick={closePopup}
					className="nav-btn"
					id="user-profile-hide">
					<i className="ri-close-line"></i>
				</Button>
			</div>

			<ModalBody className="">
				<Feedback
					currentDate={data.currentDate}
					feedbackQuestions={feedbackQuestions}
					speedData={data.speedData}
					myFeedback={myFeedback[data?.uidMatch]}
					toggleDrawer={() => {}}
					withoutReport={true}
				/>
			</ModalBody>

			
		</Modal>
	);
};
