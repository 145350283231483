import { useState, useEffect } from "react";
import _ from "lodash";
import { useDispatch } from "react-redux";

import InputQuestion from "../Questions/InputQuestion";
import ProfileFields from "consts/profileFields";
import { updateProfile } from "redux/profile/actions";
import nameImage from "assets/images/registerImg/name.png";
import Stance from "consts/stance";
import LoginSteps from "consts/loginSteps";
import i18next from "i18next";
import isMobile from "helpers/isMobile";
import { Box } from "@mui/material";

export default ({ profile, stepName, steps, goToNamedStep, onFinalStepFinish }) => {
	const index = steps.indexOf(stepName);
	const total = steps.length;
	const dispatch = useDispatch();
	const [isClickedNext, setIsClickedNext] = useState(false);
	const [hasError, setHasError] = useState(false);

	const errMsgBox = (
		<Box>
			<b style={{ display: "block" }}>{"אממ… לא כדאי שנציג אותך כמו שצריך?"}</b>
			<>{`למשתמשים שממלאים "על עצמי" באופן מעניין ומפורט סיכוי גבוה להצליח!`}</>
		</Box>
	);

	const handleOnSubmit = (value) => {
		dispatch(updateProfile(ProfileFields.AboutMe, value?.trim() ?? value));
		setIsClickedNext(true);
	};

	const handleOnBackButtonClick = () => {
		if (
			profile[ProfileFields.Stance] !== Stance.Mesorati &&
			steps[index - 1] === LoginSteps.KosherFood
		) {
			goToNamedStep(steps[index - 3]);
		} else {
			goToNamedStep(steps[index - 1]);
		}
	};

	useEffect(() => {
		if (isClickedNext && !_.isNil(profile[ProfileFields.AboutMe])) {
			if (total - 1 <= index) {
				onFinalStepFinish();
			} else {
				goToNamedStep(steps[index + 1]);
			}
		}
	}, [isClickedNext, profile]);

	useEffect(() => {
		if (!isMobile()) return;
		const heightW = document.documentElement.clientHeight;
		const loginHolder = document.querySelector(".loginHolder");
		loginHolder && (loginHolder.style.height = heightW + "px");
	}, []);

	return (
		<div className="loginHolder nameHolder">
			<div className="imageForPage fullWidth left">
				<img src={nameImage} />
			</div>
			<InputQuestion
				title={i18next.t("About You")}
				onSubmit={handleOnSubmit}
				showBackButton={index > 0}
				onBackButtonClick={handleOnBackButtonClick}
				type="textarea"
				isAboutMe={true}
				errMsg={errMsgBox}
				hasError={hasError}
				setHasError={setHasError}
			/>
		</div>
	);
};
