import { Box } from "@mui/material";
import { getAgeByDob } from "helpers/date";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import Feedback from "./Feedback";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";

export default function DateItem({
	item,
	current,
	feedbackQuestions,
	speedData,
	myFeedback = {},
	closeVideoDate = () => {},
	toggleDrawer = () => {},
}) {
	const itsBreak = !item?.uidMatch;
	const [isCurrent, setIsCurrent] = useState(current?.time === item?.time);
	const [timePassed, setTimePassed] = useState(moment().isAfter(moment(item?.time, "HH:mm")));
	const [open, setOpen] = useState(!itsBreak && isCurrent);
	const toggleOpen = () => timePassed && setOpen(!open);
	const myRef = useRef();

	const handleClose = (e) => {
		e.stopPropagation();
		toggleDrawer(false);
		closeVideoDate();
	};
	useEffect(() => {
		if (timePassed) return;

		const interval = setInterval(() => {
			const passed = moment().isAfter(moment(item?.time, "HH:mm"));
			if (passed) setTimePassed(passed);
		}, 10000);

		return () => clearInterval(interval);
	}, []);

	const scrollRefIntoView = () => {
		if (myRef.current) {
			myRef.current.scrollIntoView({ behavior: "smooth" });
		}
	};
	useEffect(() => {
		setIsCurrent(current?.time === item?.time);
		if (!open) setOpen(current?.time === item?.time && !itsBreak);
	}, [current]);

	useEffect(() => {
		if (isCurrent) scrollRefIntoView();
	}, [open]);

	if (itsBreak) {
		return (
			<Box
				className={`lineDate breakLine${timePassed ? " timePassed" : ""}${
					isCurrent ? " current" : ""
				}`}>
				<span>{item.name}</span>
			</Box>
		);
	}

	return (
		<Box
			className={`lineDate${timePassed ? " timePassed" : ""}${isCurrent ? " current" : ""}${
				open ? " open" : ""
			}`}>
			<Box className="wrapContent" onClick={toggleOpen} ref={myRef}>
				<span className="time">{item.time}</span>
				<span>{item.name}</span>
				{/* 	{item?.dob && <span>{getAgeByDob(item.dob)}</span>} */}
				{isCurrent && (
					<span
						onClick={handleClose}
						style={{
							position: "absolute",
							left: "55px",
							cursor: "pointer",
						}}>{`כניסה לדייט`}</span>
				)}
				{timePassed && (
					<ArrowBackIosNewIcon
						style={{
							position: "absolute",
							left: "20px",
							color: !isCurrent ? "#2d5086" : "#fff",
							transform: open ? "rotate(90deg)" : "rotate(270deg)",
						}}
					/>
				)}
			</Box>

			{timePassed && (
				<Feedback
					currentDate={item}
					feedbackQuestions={feedbackQuestions}
					speedData={speedData}
					myFeedback={myFeedback[item?.uidMatch]}
					toggleDrawer={toggleDrawer}
				/>
			)}
		</Box>
	);
}
