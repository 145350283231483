import "assets/scss/custom/pages/explanationSpeedDate.scss";
import { Box } from "@mui/system";
import useNavigator from "hooks/useNavigator";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { getUserProfile } from "redux/profile/selectors";
import speedDatingService from "services/speedDatingService";
import usePopups from "hooks/usePopups";
import moment from "moment";
import FooterBtn from "components/speedDate/FooterBtn";
import CommonTemplate from "components/speedDate/CommonTemplate";
import speedDateExplain from "consts/speedDateExplain";
import speedDateStatus from "consts/speedDateStatus";
import speedDateSubscriptionTypes from "consts/speedDateSubscriptionTypes";
import userSpeedDateStatus from "consts/userSpeedDateStatus";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

export default function ExplanationSpeedDate({ url = "", isDrawer = false }) {
	let { urlName } = useParams();
	const currentUser = useSelector(getUserProfile) || {};
	const navigate = useNavigator();
	const { showFittedMessagePopup, showLoginPopup, showSubscriptionForSpeedDatePopup } = usePopups();

	const [timeEventText, setTimeEventText] = useState("");
	const [speedData, setSpeedDate] = useState({});
	const [displayUrlName, setDisplayUrlName] = useState(urlName ?? url);
	const [speedDateUser, setSpeedDateUser] = useState(false);
	const [loading, setLoading] = useState(false);
	const [showArrow, setShowArrow] = useState(true);
	const containerRef = useRef(null);

	const handleArrowClick = () => {
		setShowArrow(false);
		if (containerRef.current) {
			window.scrollTo(0, containerRef.current.scrollHeight);
		}
	};
	const navigateToHomePage = () => {
		if (isDrawer) return;
		return navigate.navigateToHomePage();
	};
	const navigateToVideoDatePage = () => {
		if (isDrawer) return;
		return navigate.navigateToAccountVideoDate({ urlName: displayUrlName });
	};
	const navigateToPayPage = () => {
		if (isDrawer) return;
		return navigate.navigateToPayVideoPage({ urlName: displayUrlName });
	};

	const getTimeEventText = (event) => {
		if (!event) return;
		let text = "";
		const now = moment();
		const eventTime = moment(event.eventDate);
		const duration = moment.duration(eventTime.diff(now));

		const days = Math.floor(duration.asDays());

		switch (days) {
			case 0:
				text = "היום";
				break;
			case 1:
				text = "מחר";
				break;
			case 2:
				text = "מחרתיים";
				break;
			default:
				text = `בעוד ${days} ימים`;
				break;
		}
		setTimeEventText(text);
	};

	const registerSpeedDateUser = async () => {
		setLoading(true);

		if (
			speedData?.status !== speedDateStatus.start ||
			(speedData?.endUsersRegister && moment().isAfter(moment(speedData?.endUsersRegister))) ||
			speedDateUser?.status === userSpeedDateStatus.confirmed
		)
			return navigateToVideoDatePage();

		if (speedDateUser?.status === userSpeedDateStatus.canceled) {
			const res = await speedDatingService.confirmUserSpeedDating(speedData?.key);
			return navigateToVideoDatePage();
		}

		speedDatingService.registerUserToSpeedDating(speedData?.key).then((data) => {
			if (data?.data?.success || data?.data?.msg == "user already register") {
				if (!speedData?.price) navigateToVideoDatePage();
				else return navigateToPayPage();
			}

			if (data?.data.msg === "max users") {
				const text = (
					<div>
						<b>{`האירוע התמלא מהר משחשבנו...😮`}</b>
						<div>{` ככה שלצערנו, הפעם לא נותרו מקומות פנויים 🤷‍♀️`}</div>
						<div>{` אבל אל דאגה... מתוכננים עוד המון אירועים בהמשך!`}</div>
						<div>{` מוזמנים לעקוב ❤️`}</div>
					</div>
				);

				showFittedMessagePopup(text, false);
			}
			if (data?.data.msg === "user blocked") {
				const text = (
					<div>
						<b>{`מאחר ולא הופעת לאירוע וידאו דייט שהתקיים, לא ${
							currentUser?.gender ? "תוכלי" : "תוכל"
						} להירשם שוב.`}</b>
					</div>
				);

				showFittedMessagePopup(text, false);
			}
			if (data?.data.msg === "user not have subscription") {
				showSubscriptionForSpeedDatePopup();
			}
			navigateToVideoDatePage();

			setLoading(false);
		});
	};

	const handleRegisterBtn = () => {
		if (!currentUser?.uid) return showLoginPopup(registerSpeedDateUser);
		if (!speedData?.key) return;
		registerSpeedDateUser();
	};

	useEffect(() => {
		if (!currentUser?.uid) return;
		if (!url && !urlName) return navigateToHomePage();
		if (!displayUrlName) return;
		speedDatingService.getSpeedDateInfoByUrlName(displayUrlName).then((data) => {
			if (!data) return navigateToHomePage();
			const sData = Object.values(data || {});
			if (!sData || !sData?.length) return navigateToHomePage();
			const canRegister = speedDatingService.checkIfUserCanRegisterToEventByFilters(
				sData[0],
				currentUser
			);
			if (!canRegister) {
				return navigate.navigateToAccountVideoDate({ urlName: urlName });
			}

			setSpeedDate(sData[0]);
			getTimeEventText(sData[0]);
		});
	}, [displayUrlName, currentUser?.uid]);

	useEffect(() => {
		if (!speedData?.key || !currentUser?.uid) return;

		speedDatingService
			.getUserInSpeedDate(speedData.key, currentUser.uid)
			.then((userData) => setSpeedDateUser(userData));
	}, [speedData?.key, currentUser?.uid]);
	useEffect(() => {
		if (url) setDisplayUrlName(url);
		if (urlName) setDisplayUrlName(urlName);
	}, []);

	return (
		<Box className="registerSpeedDateWrapper explanationSpeedDateWrapper">
			<CommonTemplate bluePage={false} wideImg={false} withBlueImg={true}>
				<Box className="speedDateContentWrapper" ref={containerRef}>
					<Box className="text">{`האירוע הקרוב מתחיל ${timeEventText}`}</Box>

					<h3 className="text">{`מה זה בעצם וידאו דייט ומה בדיוק הולך לקרות באירוע?`} </h3>

					<Box className="explainImgBox">
						{speedDateExplain(speedData?.price ?? 0).map((item, i) => (
							<Box key={i} className="explainImgItem">
								<img src={item.image} />
								<span className="text">{item.text}</span>
							</Box>
						))}
					</Box>

					<Box className="text" style={{ fontWeight: "bold" }}>
						{`מנסיון - הולך להיות אירוע ספידייט מיוחד!`}
					</Box>

					{!isDrawer && (
						<Box>
							<FooterBtn
								handleClick={handleRegisterBtn}
								text={`יאללה, להרשמה >>`}
								loading={loading}
							/>
						</Box>
					)}
					{!!showArrow && (
						<Box onClick={handleArrowClick} className="downArrow">
							<KeyboardArrowDownIcon />
						</Box>
					)}
				</Box>
			</CommonTemplate>
		</Box>
	);
}
