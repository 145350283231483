import { useState, useEffect, useRef } from "react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import i18next from "i18next";

import InputQuestion from "../Questions/InputQuestion";
import ProfileFields from "consts/profileFields";
import { updateProfile } from "redux/profile/actions";
import nameImage from "assets/images/registerImg/name.png";
import { getUserProfile } from "redux/profile/selectors";
import Stance from "consts/stance";
import LoginSteps from "consts/loginSteps";
import isMobile from "helpers/isMobile";
import { Box } from "@mui/material";

export default ({
	profile,
	stepName,
	steps,
	goToNamedStep,
	onFinalStepFinish,
	handleRefresh = () => {},
}) => {
	const index = steps.indexOf(stepName);
	const total = steps.length;
	const dispatch = useDispatch();
	const [isClickedNext, setIsClickedNext] = useState(false);
	const loggedInUserProfile = useSelector(getUserProfile);
	const ref = useRef();
	const [hasError, setHasError] = useState(false);

	const errMsgBox = (
		<Box>
			<b style={{ display: "block" }}>{"אופסי, יש למלא שם פרטי בלבד."}</b>
			<>{`במידה ומדובר בשם שני שחשוב לך שיופיע - ניתן לערוך זאת בעמוד הגדרות החשבון שלך בהמשך.`}</>
		</Box>
	);

	const mainClass =
		process.env.REACT_APP_ENV === "shlish" ? "loginHolder nameHolder" : "loginHolder";

	const handleOnSubmit = (value) => {
		ref.current = false;
		dispatch(updateProfile(ProfileFields.Name, value?.trim() ?? value));
		setIsClickedNext(true);
	};

	const handleOnBackButtonClick = () => {
		if (
			profile[ProfileFields.Stance] !== Stance.Mesorati &&
			steps[index - 1] === LoginSteps.KosherFood
		) {
			goToNamedStep(steps[index - 3]);
		} else {
			goToNamedStep(steps[index - 1]);
		}
	};

	useEffect(() => {
		if (isClickedNext && !_.isNil(profile[ProfileFields.Name])) {
			if (total - 1 <= index) {
				onFinalStepFinish();
			} else {
				goToNamedStep(steps[index + 1]);
			}
		}
	}, [isClickedNext, profile]);

	useEffect(() => {
		if (!isMobile()) return;
		const heightW = document.documentElement.clientHeight;
		const loginHolder = document.querySelector(".loginHolder");
		loginHolder && (loginHolder.style.height = heightW + "px");
	}, []);

	useEffect(() => {
		if (!profile?.name) ref.current = true;
		if (profile?.name && !!ref.current) handleRefresh();
	}, [profile?.name]);

	return (
		<div className={mainClass}>
			<div className="imageForPage fullWidth left">
				<img src={nameImage} />
			</div>
			<InputQuestion
				title={i18next.t("Login.What is your name")}
				onSubmit={handleOnSubmit}
				showBackButton={index > 0}
				onBackButtonClick={handleOnBackButtonClick}
				initVal={loggedInUserProfile.name || ""}
				errMsg={errMsgBox}
				hasError={hasError}
				setHasError={setHasError}
				isName={true}
			/>
		</div>
	);
};
