import "firebase/database";
import httpsCallable from "./httpsCallable";
import AnonymousCallService from "services/anonymousCall";
import localStorage from "services/localStorage";

class AlternativeLoginService {
	//	BASE_REF = "articles";
	async getIp() {
		try {
			const response = await fetch("https://api.ipify.org?format=json");
			const { ip } = await response.json();

			return ip ?? "";
		} catch (error) {
			console.error("Error fetching the IP address:", error);
			return "";
		}
	}

	async getTokenLoginByCode(phone, code) {
		const affId = localStorage.get("affId") || null;

		const result = await AnonymousCallService.call("getTokenByCode", {
			code,
			phone,
			affId,
			ip: (await this.getIp()) || null,
		});

		return result;
	}

	async authWithCode(phone) {
		const result = await AnonymousCallService.call("authWithCode", { phone });

		return result;
	}
}

export default new AlternativeLoginService();
