import { createSelector } from "reselect";
import { isNil } from "lodash";

import { getGoogleIapIsOn, getSubscriptionLimits } from "../system/selectors";
import Subscription from "consts/subscription";
import SubscriptionLimits from "consts/subscriptionLimits";
import ApiRequestStatus from "consts/apiRequestStatus";
import { getIsPlatformAndroid } from "redux/init/selectors";
import subscriptionStatus from "consts/subscriptionStatus";

const getSubscription = (state) => state?.subscription;

export const getSubscriptionData = createSelector(
	getSubscription,
	(subscription) => subscription.data
);
export const getHasSubscription = createSelector(
	getSubscriptionData,
	(subscription) => !!subscription && subscription.status === subscriptionStatus.ACTIVE
);

export const getSubscriptionsIsSoonExpired = createSelector(
	getSubscriptionData,
	(subscription) =>
		subscription &&
		subscription.status === subscriptionStatus.ACTIVE &&
		!subscription.renewable &&
		subscription.finish_date < Date.now() + 60 * 60 * 24 * 7 * 1000
);

export const getUserSubscriptionType = createSelector(getSubscriptionData, (subscription) => {
	return isNil(subscription) ||
		isNil(subscription?.status) ||
		subscription?.status === subscriptionStatus.EXPIRED ||
		subscription?.status === subscriptionStatus.NONE ||
		subscription?.status === subscriptionStatus.SUSPEND
		? Subscription.NONE
		: subscription.type;
});

export const getReadMessagesLimits = createSelector(
	getSubscriptionLimits,
	(subscriptionLimits) => subscriptionLimits[SubscriptionLimits.ReadMessages]
);

export const canReadMessages = createSelector(
	getUserSubscriptionType,
	getReadMessagesLimits,
	(subscriptionType, subscriptionReadMessageLimits) =>
		subscriptionReadMessageLimits?.[subscriptionType] === true
);

export const getSale = createSelector(getSubscription, (subscription) => {
	const res = subscription?.sale;
	return res;
});

export const getSubscriptionPlans = createSelector(
	getSubscription,
	getIsPlatformAndroid,
	getGoogleIapIsOn,
	(subscriptions, isPlatformAndroid, googleIapIsOn) => {
		return subscriptions?.plans.filter(
			(plan) => (googleIapIsOn && isPlatformAndroid) === !!plan.google_only
		);

		// return subscriptions?.plans.filter((plan) => !plan.google_only);
	}
);

export const getSubscriptionPlansExists = createSelector(getSubscriptionPlans, (plans) => {
	return plans.length > 0;
});

export const getSelectedPlan = createSelector(getSubscription, (subscriptions) => {
	return subscriptions.selectedPlan;
});

export const getFetchCardcomApiStatus = createSelector(
	getSubscription,
	(subscriptions) => subscriptions.fetchCardcomApiStatus
);

export const getGoogleApiStatus = createSelector(
	getSubscription,
	(subscriptions) => subscriptions.googleApiStatus
);

export const getFetchSubscriptionPlansApiStatus = createSelector(
	getSubscription,
	(subscriptions) => subscriptions.fetchSubscriptionPlansApiStatus
);

export const getCardComUrl = createSelector(
	getSubscription,
	(subscriptions) => subscriptions.cardComUrl
);

export const getIsPurchaseDone = createSelector(
	getSubscription,
	(subscriptions) =>
		subscriptions.purchaseStatus === ApiRequestStatus.SUCCESSFULLY ||
		subscriptions.purchaseStatus === ApiRequestStatus.FAILED
);

export const getPurchaseStatus = createSelector(
	getSubscription,
	(subscriptions) => subscriptions.purchaseStatus
);

export const getSubscriptionPlan = createSelector(getSubscription, (subscriptions) => {
	const key = subscriptions.data?.selected_plan;
	return subscriptions.plans?.find((plan) => plan.key === key) || {};
});
